
import { defineComponent, onMounted, ref, watch } from "vue";
import { hideModal } from "../helper/Dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useStore } from "vuex";
import { VerifyOTPModel } from "@/domain/auth/verify-otp/model/VerifyOTPModel";
import SwalNotification from "../plugins/SwalNotification";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { Actions } from "../store/enums/StoreEnums";
import { debounce } from "lodash";

export default defineComponent({
  name: "otp-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const swalNotification = new SwalNotification();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const otpController = store.state.ControllersModule.otpController;
    const countdownTimer = ref();
    const remainingAttempts = ref();
    const verifyOTPModel = ref<VerifyOTPModel>({});
    const jwtTokenManager = new JwtTokenManager();

    let timer: any;
    let submitting = ref(false);

    let token: undefined | string = undefined;

    const validationSchema = Yup.object().shape({
      verifyCode: Yup.string()
        .min(11, t("validators_error.codeInvalid"))
        .label("verifyCode"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitting.value = true;
      submitButtonRef.value.disabled = true;
      // Activate indicator

      remainingAttempts.value = Number(store.getters.remainingAttempts);

      verifyOTPModel.value.phoneNumber = store.getters.phoneNumber;
      verifyOTPModel.value.countryCode = store.getters.countryCode;
      verifyOTPModel.value.otp = String(verifyOTPModel.value.otp).replace(
        /\D/g,
        ""
      );

      if (submitButtonRef.value && remainingAttempts.value > 0) {
        submitButtonRef.value.disabled = false;

        otpController
          .verifyOTP(verifyOTPModel.value)
          .then((response) => {
            if (response.isSuccess) {
              clearInterval(timer);
              store.dispatch("expireTime", "");
              store.dispatch(Actions.VERIFY_AUTH);

              submitButtonRef.value?.removeAttribute("data-kt-indicator");

              hideModal(createAPIKeyModalRef.value);
              if (
                response.getValue().name == null ||
                response.getValue().surname == null
              ) {
                router.push({ name: "setProfileInfo", query: { token: token } });
              } 
              else if(token) {
                router.push({ name: ROUTE_PAGES.ACCEPT_INVITE, query: { token: token } });
              }
              else {
                router.push({ name: ROUTE_PAGES.DASHBOARD });
              }
              /*
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_VERIFY),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              })
              */
            } else {
              remainingAttempts.value = remainingAttempts.value - 1;
              store.dispatch("remainingAttempts", remainingAttempts.value);

              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          })
          .finally(() => {
            submitting.value = false; 
          });
      } else {
        Swal.fire({
          text: t(SWAL_MESSAGES.ERROR.ERROR_VERIFY_REMAINING_ATTEMPTS),
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: t("common.button.ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          hideModal(createAPIKeyModalRef.value);
          router.push({ name: ROUTE_PAGES.SIGN_IN });
          window.location.reload();
        });
      }
    };

    const checkOTP = debounce(() => {
      if (verifyOTPModel.value.otp && verifyOTPModel.value.otp.length === 11) {
        submit();
        verifyOTPModel.value.otp = ""
      }
    }, 300);

    watch(
      () => store.getters.expireTime,
      (newExpireTime) => {
        if (newExpireTime != "") startCountdown(newExpireTime);
      }
    );

    let alertShown = false; // Flag to track if the alert has been shown

    const startCountdown = (expireTime) => {
      clearInterval(timer);

      if (store.getters.expireTime != "") {
        const now = new Date().getTime();
        const expireDateTime = new Date(expireTime).getTime();
        const distance = expireDateTime - now;
        if (distance > 0) {
          timer = setInterval(() => {
            const now = new Date().getTime();
            const distance = expireDateTime - now;

            const minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            )
              .toString()
              .padStart(2, "0");
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)
              .toString()
              .padStart(2, "0");

            countdownTimer.value = `${minutes}:${seconds}`;
            if (distance <= 0 && !alertShown) {
              // Check if the alert hasn't been shown yet

              alertShown = true; // Set the flag to true
              clearInterval(timer);
              if (
                !jwtTokenManager.getToken() ||
                jwtTokenManager.getToken() == null
              ) {
                Swal.fire({
                  text: t(SWAL_MESSAGES.ERROR.TIME_ERROR),
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: t("common.button.ok"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(createAPIKeyModalRef.value);
                  router.push({ name: "login" });
                  window.location.reload();
                });
              }
            }
          }, 900);
        }
      } else {
        clearInterval(timer);
      }
    };

    onMounted(async () => {
      token = route.query.token?.toString();
    });

    return {
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
      createAPIKeyModalRef,
      verifyOTPModel,
      countdownTimer,
      checkOTP,
      submitting
    };
  },
});
